import styled from "styled-components";

export default styled.h2`
  font-size: 2rem;
  font-weight:normal;
  text-align: ${props => props.textAlign && props.textAlign};

  margin: ${props => props.margin && props.margin};
  padding: ${props => props.padding && props.padding};
  border-right: ${props => props.borderRight && props.borderRight};
`