import styled from "styled-components";
import {devices} from "../../utils/devices";

export default styled.h1`
  font-size: ${props => props.fontSize ? props.fontSize : "2.5rem"};
  font-weight:bold;
  font-family: ${props => props.font && props.font};
  text-transform: ${props => props.uppercase && "uppercase"};
  text-align: ${props => props.textAlign && props.textAlign};
  margin: ${props => props.margin && props.margin};
  
  @media ${devices.maxLaptop} {
    text-align: ${props => props.textAlignMobile && props.textAlignMobile};
  }
`