import React, {useEffect, useState} from "react";
import FormField from "../components/items/FormField";
import styled from "styled-components";
import DivProps from "../components/items/DivProps";
import Submit from "../components/items/Submit";
import {theme} from "../utils/theme";
import Container from "../components/items/Container"
import ContainerGlobal from "../components/items/ContainerGlobal"
import Layout from "../components/Layout"
import Cookies from "js-cookie";
import {userProfile} from "../utils/queries/user";
import {clientAuth, client} from "../utils/api";
import {getAllProducts} from "../utils/queries/product";
import {useRouter} from 'next/router'
import {getCsrfToken, getSession, signIn} from "next-auth/react";
import TitleH1 from "../components/items/TitleH1";
import TitleH2 from "../components/items/TitleH2";
import LinkDiv from "../components/items/LinkDiv";

export default function NotFound({csrfToken}) {
    return (<Layout title={`404 - Page non trouvée - To Drink List`}
                    description={`Page non trouvée - erreur 404 - To Drink List`}>
        <LoginPage>
            <Content>
                <TitleH1 fontSize={"4.5rem"} margin={".7rem"}>Oups</TitleH1>
                <TitleH1>404 - Page non trouvée</TitleH1>
                <LinkDiv href={'/'} color={"#FFFFFF"} bg={theme.colors.primary} bgHover={"#FFFFFF"} colorHover={theme.colors.primary} border={theme.colors.primary} borderHover={"primary"} padding={"1rem 2rem"}>Retour à l'accueil</LinkDiv>
            </Content>
        </LoginPage>
    </Layout>)
}

const LoginPage = styled.div`
  display: flex;
  height: 100vh;



`

const Heading = styled.div`
  max-width: 35%;
  background-color: #C4D4B1;

  background-image: url("/images/Nicolas_Decarsin_Vineyard.jpg");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;

  p {
    text-align: center;
    font-size: 2rem;
    margin-top: 8rem;
    display: flex;
    align-items: center;
    color: #865C6C;
  }
`

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    width: 100%;
    max-width: 500px;

    h2 {
      font-size: 1.5rem;
      margin-bottom: 5rem;
    }
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  @media (max-width: 991px) {
    max-width: 550px;
    margin: 4rem auto auto auto;
  }
`

const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  //row-gap: 0.45rem;
  margin-bottom: 1.7rem;
  width: 100%;

  label {
    display: flex;
    justify-content: space-between;
    margin-bottom: .5rem;

    p {
      font-size: .9375rem;
      margin: 0;
    }

    a {
      margin: 0;
      font-size: .875rem;
      font-weight: normal;
      color: #4F3CC9;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }

  input {
    &:focus {
      background: #FFFFFF;
      border: 1px solid ${theme.colors.primary}66 !important;}
      box-shadow: 0 0 0 4px ${theme.colors.primary}15;
      border-radius: 8px;
    }
  }
`

const Error = styled.p`
  font-size: 1.6rem;
  background: #ff22005e;
  padding: 0.9rem 2rem;
  border-radius: .5rem;
  color: #ffdad4;
  font-weight: 500;
  width: max-content;
  margin: auto auto .5rem auto;
`