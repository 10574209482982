import {gql} from "@apollo/client";

export const userProfile = gql`
    query {
      me {
        id
        username
        email
          
          
      }
    }
`

export const updateAvatar = gql`
    mutation UpdateAvatar(
        $id: ID!
        $avatar: Int
    ) {
        updateUsersPermissionsUser(
            id: $id
            data: { avatar: $avatar }
        ) {
            data {
                id
                attributes {
                    username
                    email
                }
            }
        }
    }
`

export const UpdateMainQuestProgress = gql`
    mutation UpdateMainQuestProgress(
        $id: ID!
        $mainQuestProgress: Int
        $experience: Int
        $level: Int
    ) {
        updateUsersPermissionsUser(
            id: $id
            data: { mainQuestProgress: $mainQuestProgress, experience:$experience, level : $level }
        ) {
            data {
                id
                attributes {
                    username
                    email
                }
            }
        }
    }
`
